<template>
  <div class="home">
      <p>home</p>
  </div>
</template>

<script>

export default {
  name: 'home',
  data(){
      return{

      }
  },
  //创建
  created(){

  },

  //安装
  mounted(){

  },
  
  //方法
  methods:{

  }


}
</script>
<style  scoped lang="scss">

</style>