<template>
  <div class="mainCom box box_column">
    <!-- <div>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mB20">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>单位管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="hengBox"></div>
    </div> -->

    <div class="box flex1 mainBox">
      <div class="div">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" :highlight-current="true" ref="treeBox" :filter-node-method="filterNode" node-key="id" default-expand-all>
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span :title="node.label" class="em-tree-text">{{ node.label }}</span>
          </span>
        </el-tree>
      </div>

      <div class="flex1">
        <el-button @click="changeValue" style="margin-bottom: 30px; padding: 0" :class="!myUpload ? 'disable_icon' : ''">
          <svg class="icon mR5" aria-hidden="true">
            <use xlink:href="#iconxingzhuang"></use>
          </svg>
          <span>添加下级单位</span>
        </el-button>

        <el-table :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%">
          <el-table-column align="center" prop="name" label="下级单位名称"></el-table-column>
          <el-table-column align="center" prop="projectCount" label="桥梁数量"></el-table-column>
          <el-table-column align="center" prop="userCount" label="成员数量"></el-table-column>

          <el-table-column align="center" min-width="25" prop="id" label="操作">
            <template slot-scope="scope">
              <!-- <svg class="icon fs22 pointer" aria-hidden="true" @click="handleEdit(scope.row.id)">
                <use xlink:href="#iconbianji" />
              </svg> -->
              <!-- <svg @click="deleteFile(scope.row)" class="icon fs22 operate-txt ml24" aria-hidden="true">
                                <use xlink:href="#iconshanchu"></use>
                            </svg> -->

              <!-- <svg class="icon fs22 ml24 pointer" aria-hidden="true" @click="deleteFile(scope.row)">
                <use xlink:href="#iconshanchu" />
              </svg> -->
              <span type="text" class="colorText mR20 pointer" size="small" @click="handleEdit(scope.row.id)" :class="!myOption ? 'disable_icon' : ''">修改</span>

              <span type="text" class="colorText pointer" size="small" @click="deleteFile(scope.row)" :class="!myDel ? 'disable_icon' : ''">删除</span>
            </template>
          </el-table-column>
          <template slot="empty">
            <span class="iconfont iconzanwushuju-"></span>
            <span>暂无数据</span>
          </template>
        </el-table>
        <common-pagination :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
      </div>
    </div>

    <!--添加 修改-->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="400">
      <el-form :model="form" label-width="120px" :rules="rules" ref="form">
        <el-form-item label="单位名称" prop="name">
          <el-input v-model="form.name" autocomplete="off" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="上级单位" prop="" class="textLeft">
          <span class="white">{{ fu }}</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer box around">
        <div>
          <el-button class="btnStyle sureBtn mR40" @click="resetForm('form')">取消</el-button>
          <el-button class="btnStyle backBtn" @click="submitForm('form')">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 是否删除 -->
    <el-dialog :title="title" :visible.sync="dialogVisible1" width="400">
      <div class="textC white">
        {{ title1 }}
      </div>
      <div slot="footer" class="dialog-footer box around">
        <div>
          <el-button class="btnStyle sureBtn mR40" @click="resetForm1()">取消</el-button>
          <el-button class="btnStyle backBtn" @click="submitForm1()">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MenuTree from "../../components/NavMenu";
import CommonPagination from "@/components/Pagination";

export default {
  components: {
    MenuTree: MenuTree,
    CommonPagination: CommonPagination
  },
  data() {
    return {
      //子id
      itemId: "",
      type: 1, // 1添加， 2修改， 3删除

      detId: "",
      title1: "",

      //分页
      total: 0,
      currentPage: 1,

      disabled: "",
      active: "1",
      isCollapse: 1,

      title: "",
      dialogVisible: false,
      dialogVisible1: false,
      fu: "",
      form: {
        name: ""
      },

      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" }
          // { min: 1, max: 12, message: '长度在 3 到 12 个字符', trigger: 'blur' }
        ]
      },
      tableData: [],
      data: [
        {
          id: "1",
          label: "一级 1",
          level: "1",
          children: [
            {
              id: "4",
              label: "二级 1-1",
              level: "2",
              children: [
                {
                  id: "9",
                  label: "三级 1-1-1",
                  level: "3"
                },
                {
                  id: "10",
                  label: "三级 1-1-2",
                  level: "3"
                }
              ]
            }
          ]
        },
        {
          id: "2",
          label: "一级 2",
          children: [
            {
              id: "5",
              label: "二级 2-1"
            },
            {
              id: "6",
              label: "二级 2-2"
            }
          ]
        },
        {
          id: "3",
          label: "一级 3",
          children: [
            {
              id: "7",
              label: "二级 3-1"
            },
            {
              id: "8",
              label: "二级 3-2"
            }
          ]
        }
      ],
      id: "", //点击时获取的id
      defaultProps: {
        children: "children",
        label: "name"
      }
    };
  },
  mounted() {
    this.selectOne(); //进入时获取树
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    //进入时选中项目获取树，选择第一项
    selectOne() {
      this.$axios
        .get(`${this.baseURL}base/baseDepartment/user/department`)
        .then((res) => {
          console.log("tree", res.data.data);
          this.data = res.data.data;
          this.id = this.data[0].id;
          this.list(this.id);
          this.fu = this.data[0].name;
          this.$nextTick(() => {
            this.$refs.treeBox.setCurrentKey(this.id);
          });
        })
        .catch((err) => {});
    },

    //树列表
    treeList() {
      this.$axios
        .get(`${this.baseURL}base/baseDepartment/user/department`)
        .then((res) => {
          console.log("tree", res.data.data);
          this.data = res.data.data;
          this.$nextTick(() => {
            this.$refs.treeBox.setCurrentKey(this.id);
          });
        })
        .catch((err) => {});
    },

    //分页
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.getData()
    },

    //点击编辑窗口
    handleEdit(id) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }

      this.form.name = "";
      this.type = 2;
      console.log("id", id);
      this.dialogVisible = true;
      this.title = "编辑";
      this.itemId = id;
    },

    //取消按钮
    resetForm(form) {
      this.$refs[form].resetFields();
      this.dialogVisible = false;
    },

    //确定按钮
    submitForm(form) {
      console.log(this.form.name);
      console.log(this.id);

      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.type == 1) {
            this.add();
          }
          if (this.type == 2) {
            this.edit();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    add() {
      var param = new URLSearchParams();
      param.append("name", this.form.name);
      param.append("parentId", this.id);

      this.$axios
        .post(`${this.baseURL}base/baseDepartment/?name=${this.form.name}&parentId=${this.id}`)
        .then((res) => {
          console.log("添加", res);
          this.dialogVisible = false;
          this.treeList(); //重新获取树
          this.list(this.id); //重新获取表格
        })
        .catch((err) => {});
    },
    edit() {
      var param = new URLSearchParams();
      param.append("name", this.form.name);
      param.append("parentId", this.id);

      this.$axios
        .put(`${this.baseURL}base/baseDepartment/?name=${this.form.name}&id=${this.itemId}`)
        .then((res) => {
          console.log("修改", res);
          this.dialogVisible = false;
          this.treeList(); //重新获取树
          this.list(this.id); //重新获取表格
        })
        .catch((err) => {});
    },

    //点击删除按钮
    deleteFile(item) {
      if (!this.myDel) {
        this.$message.error("您没有权限！");
        return;
      }
      console.log("获取选项", item);
      if (item.projectCount != 0 || item.userCount != 0) {
        this.title = "提示";
        this.type = 4; //提示
        this.title1 = "请先删除单位下的桥梁和成员，再删除该单位";
      } else {
        this.title = "删除";
        this.type = 3; //删除
        this.title1 = "确认删除该单位？";
      }

      this.dialogVisible1 = true;
      this.detId = item.id;
    },
    //取消删除
    resetForm1() {
      this.dialogVisible1 = false;
    },
    //删除
    submitForm1() {
      if (this.type == 3) {
        this.$axios
          .delete(`${this.baseURL}base/baseDepartment/?id=${this.detId}`)
          .then((res) => {
            console.log("删除", res);
            this.dialogVisible1 = false;
            this.treeList(); //重新获取树
            this.list(this.id); //重新获取表格
          })
          .catch((err) => {});
      } else {
        this.dialogVisible1 = false;
      }
    },

    filterNode() {},

    getItem(num, fu, item, e) {
      console.log("num", num);
      console.log("fu", fu);
      console.log("item", item);
      console.log("e", e.target);
    },

    handleOpen(key, keyPath, item) {
      console.log(111, key, keyPath, item);
    },
    handleClose(key, keyPath) {
      console.log(2222, key, keyPath);
    },

    //点击添加下级单位
    changeValue() {
      if (!this.myUpload) {
        this.$message.error("您没有权限！");
        return;
      }

      this.form.name = "";
      this.type = 1;
      this.dialogVisible = true;
      this.title = "添加下级单位";
    },
    //点击树获取父子id
    handleNodeClick(item, e) {
      console.log("data", item);
      this.id = item.id;
      console.log("e", e.parent.data);
      this.fu = item.name; //名称
      this.list(this.id);
    },

    //获取列表数据
    list(id) {
      //  this.$axios.get(`${this.baseURL}base/baseDepartment/department/${id}`)
      this.$axios
        .get(`${this.baseURL}base/baseDepartment/department/${this.currentPage}/10/${id}`)
        .then((res) => {
          console.log("根据父得到子", res);
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch((err) => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.mainCom {
  width: 100%;
  height: 90%;
}

.mainBox {
  width: 100%;
  height: 100%;
}

::v-deep .el-button {
  width: 157px !important;
  height: 32px !important;
}

::v-deep .el-tree {
  color: #fff;
  // height: 100%;
  background: transparent !important;
  // border-left: 1px solid #5E5E5F;
  // border-right: 1px solid #5E5E5F;
  border-bottom: 1px solid #5e5e5f;
}

::v-deep .el-tree-node__content:hover {
  //触摸树
  background: transparent;
}

::v-deep .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  //高亮
  color: #6ecbff;
  // background:  #6ECBFF;
}

::v-deep .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background: transparent;
}

//弹框
::v-deep .el-dialog__body {
  padding-top: 60px;
  padding-left: 120px;
  padding-right: 120px;
}

.btnStyle {
  text-align: center;
  display: inline-block;
  width: 100px !important;
  height: 32px !important;
  padding: 0;
}

::v-deep .btnStyle.sureBtn {
  background: transparent;
  border: 1px solid #2667db;
}

.activeIndex {
  background: chocolate;
}

//树的样式
::v-deep .el-tree-node .el-tree-node__content {
  height: 49px !important;
  line-height: 49px;
  border: 1px solid #5e5e5f;
  border-left: none;
  border-right: none;
  border-top: none;
  background: transparent;
}

::v-deep .el-tree-node .el-tree-node__children .el-tree-node__content {
  height: 49px !important;
  line-height: 49px;
  border: none;
}

.div {
  width: 210px;
  height: 100%;
  box-shadow: 0px 0px 13px 0px rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  border: 1px solid #2e373c;
  margin-right: 20px;
  overflow-y: auto;
}

.icon:nth-child(1) {
  // margin: 0 20px;
  // color: #DBF804;
  &:hover {
    color: #98b300 !important;
  }
}

.icon:nth-child(2) {
  // margin: 0 20px;
  // color: #DBF804;
  &:hover {
    color: #98b300 !important;
  }
}

.mainCom .el-table .el-table__body tr:hover td .icon {
  color: #cfd1d1;
}

// ::v-deep .el-icon-caret-right:before{
//     float: right;
//     // color: red !important;
// }
// ::v-deep .el-tree-node__content{
//     display: block !important;
// }
// ::v-deep .el-tree-node__label{
//      display: block;
//     float: left;
// }
// ::v-deep .expanded .el-tree-node__expand-icon .el-icon-caret-right{   //d箭头
//     display: block;
//     float: left;
//     margin-top: 5px;
// }
// ::v-deep .el-tree-node__content>.el-tree-node__expand-icon {  //箭头位置
//     margin-top: 12px;
// }
// ::v-deep .el-tree-node__content::before{
//     content:"\21E0" !important;
//     // content:url(imgUrl);
// }

::v-deep .custom-tree-node {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // content: attr(data-title);
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
